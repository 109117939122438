import Layout from '../components/layout';
import Wrapper from '../components/Wrapper/Wrapper';

import StickyBar from 'components/StickyBar/StickyBar';
import * as React from 'react';
import Container from '@wienenergiegithub/ui-next/src/common/components/Container/Container';
import Button from '@wienenergiegithub/ui-next/src/common/components/Button/Button';

const IndexPage = () => {
  return (
    <Wrapper>
      <Layout title="Dispo Wochenplanung">
        <StickyBar>
          <Button variant="link">Speichern</Button>
        </StickyBar>

        <Container>
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
        </Container>
      </Layout>
    </Wrapper>
  );
};

export default IndexPage;
